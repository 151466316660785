<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
        <Search />
      </v-col>
      <!-- Content -->
      <v-col align="center" class="col-sm-7 ma-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <v-row class="full-fill">
          <v-col align="center" class="ma-0 pa-0">
            <v-card class="full-fill">
              <!-- Header -->
              <v-col class="pa-0" align="center">
                <v-img src="@/assets/img/bg-account-header.png">
                  <!-- Avatar -->
                  <v-row class="ma-0 pa-5">
                    <v-spacer>
                    </v-spacer>
                    <v-col class="col-12 col-sm-3 ma-0 pa-0">
                      <v-card class="pa-2">
                        <v-img v-if="previewAvatarURL || userData.avatar"
                          :src="previewAvatarURL || userData.avatar"
                          @click="isShowingAvatarUpload=true"
                          style="cursor: pointer;"
                        ></v-img>
                        <span v-else style="color: gray;"><i class="fas fa-user fa-5x pa-10"></i></span>
                        <div v-if="isShowingAvatarUpload" class="ma-0 mt-3 pa-0">
                          <v-file-input
                            v-model="previewAvatarFile"
                            @change="previewAvatar"
                            label="Browse..."
                            filled
                            prepend-icon="mdi-camera"
                            accept="image/*"
                            class="w-100"
                          ></v-file-input>
                          <v-btn
                            @click="uploadAvatar"
                            :loading="loading"
                            class="blue darken-3 white--text"
                          >
                            Upload
                          </v-btn>
                          <v-btn
                            @click="cancelAvatarUpload"
                            :disabled="loading"
                            class="blue darken-3 ml-5 white--text"
                          >
                            Cancel
                          </v-btn>
                        </div>
                      </v-card>
                    </v-col>
                    <v-spacer>
                    </v-spacer>
                  </v-row>
                  <!-- Name and Title -->
                  <h1 class="pa-5 pt-0">{{ userData.firstName }} {{ userData.lastName }}</h1>
                </v-img>
              </v-col>
              <!-- Tabs -->
              <v-tabs v-model="tab" class="pa-5" fixed-tabs>
                <v-tab>Managed Stories</v-tab>
                <v-tab>Favorites</v-tab>
              </v-tabs>
              <v-tabs-items class="pa-sm-5 pt-0" v-model="tab">
                <!-- Tab Content - Stories You Manage -->
                <v-tab-item>
                  <StoryThumbnail v-for="story in managedStories" :key="story.id" :story="story" :storyID="story.id"/>
                  <div v-if="managedStories.length===0">You are not managing any stories.</div>
                </v-tab-item>
                <!-- Tab Content - Favorites -->
                <v-tab-item>
                  <StoryThumbnail v-for="story in favoriteStories" :key="story.id" :story="story" :storyID="story.id"/>
                  <div v-if="favoriteStories.length===0">You have no favorite stories.</div>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Ads -->
      <v-col cols="2.5">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Search from '@/components/Search'
  import StoryThumbnail from '@/components/StoryThumbnail'
  // import firebase from 'firebase'

  export default {
    name: 'Account',

    props: ['refreshManagedStories'],

    data () {
      return {
        currentTab: 'managed',
        favoriteStories: [],
        isShowingAvatarUpload: false,
        isToggle: false,
        loading: false,
        managedStories: [],
        photoFileError: '',
        photoFileUrl: '',
        previewAvatarFile: null,
        previewAvatarURL: '',
        saved: false,
        saving: false,
        tab: ''
      }
    },

    components: {
      Search,
      StoryThumbnail
    },

    watch: {
      avatar: {
        handler: function () {
          this.saved = false
        },
        deep: true
      }
    },

    computed: {
      ...mapGetters([
        'myStory',
        'userData',
        'userUID'
      ])
    },

    created () {
      this.$store.dispatch('getManagedStories', { userUID: this.userUID })
      .then((resp) => {
        this.managedStories = resp
      })
      .catch((err) => {
        this.error = err
      })

      if (!this.userData.favoriteStories) return
      for (var i = 0; i < this.userData.favoriteStories.length; i++) {
        this.$store.dispatch('getStoryData', { storyID: this.userData.favoriteStories[i] })
        .then((resp) => {
          this.favoriteStories.push(resp)
        })
        .catch((err) => {
          this.error = err
        })
      }
    },

    methods: {
      cancelAvatarUpload () {
        this.previewAvatarFile = null
        this.previewAvatarURL = ''
        this.isShowingAvatarUpload = false
      },

      previewAvatar () {
        this.previewAvatarURL = this.previewAvatarFile ? URL.createObjectURL(this.previewAvatarFile) : ''
      },

      uploadAvatar () {
        this.loading = true
        this.$store.dispatch("uploadUserAvatar", { file: this.previewAvatarFile })
        .then(() => {
          this.loading = false
          this.isShowingAvatarUpload = false
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      }
    }
  }
</script>

<style scoped>
  .search {
    max-width: 300px;
  }

  .edit-photo-icon {
    top: 26%;
  }

  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>
